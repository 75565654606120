import Api from './Api';

export function listOrders(params) {
  const exec = `
    query(
      $userId: ID!
    ) {
      storeOrders (
        userId: $userId
      ) {
        id
        checkin
        total
        totalAmount
        packageId
        complete
        reviewed
        accomplished
        confirmed
        cancelled
        approved
        status
        section {
          id
          name
        }
        product {
          id
          name
          department
          destination
          photo {
            url
          }
        }
        options {
          amount
          optionId
          price
          option {
            id
            name
            details
          }
        }
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, params)
  };
}

export function showOrder(id) {
  const exec = `
    query($id: ID!) {
      storeOrder(id: $id) {
        id
        packageId
        checkin
        total
        totalAmount
        complete
        cancelled
        confirmed
        approved
        accomplished
        status
        gatheringOption
        gatheringLocation
        transferFlightCompany
        transferFlightNumber
        userComments
        section {
          id
          name
        }
        product {
          id
          name
          supplier {
            id
            name
          }
        }
        options {
          amount
          price
          option {
            id
            name
            details
          }
        }
        users {
          name
          idType
          idNumber
        }
        transfer {
          locationFrom {
            id
            name
            kind
            geo
          }
          locationTo {
            id
            name
            kind
            geo
          }
          date
          time
        }
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, { id })
  };
}

export function cancelOrder(id) {
  const exec = `
    mutation($id: ID!) {
      cancelStoreOrder(id: $id) {
        id
        cancelled
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, { id })
  };
}
