import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';
import Moment from 'react-moment';
import Empty from '../util/Empty';
import Currency from '../util/Currency';
import { listOrders } from '../api/Orders';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
}));

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function OrdersPage() {
  const classes = useStyles();
  const [orders, setOrders] = React.useState();
  const [packages, setPackages] = React.useState();
  const [loading, setLoading] = React.useState();
  const userId = useQuery().get("userId");

  const loadOrders = () => {
    setLoading(true);

    const { fetcher } = listOrders({
      userId: userId
    });

    fetcher.then(result => {
      setLoading(false);
      setOrders(result.storeOrders);
    });
  };

  React.useEffect(() => {
    if (userId) {
      loadOrders();
    }
  }, [userId]);

  React.useEffect(() => {
    if (orders) {
      setPackages([...new Set(orders.map(o => o.packageId))]);
    }
  }, [orders]);

  React.useEffect(() => {
    if (packages) {
      console.log(packages);
    }
  }, [packages]);

  if (loading) return <CircularProgress />;

  return (
    <div className={classes.root}>
      <h1>Pacotes e reservas</h1>

      { packages ?
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Código</TableCell>
                  <TableCell>Checkin</TableCell>
                  <TableCell>Serviço</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell align="right">Status</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                { packages.map((p, i) => (
                  <>
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={p}
                    >
                      <TableCell colSpan={5}>
                        <strong>Pacote {i + 1}</strong>
                      </TableCell>
                    </TableRow>

                    {orders.filter(o => o.packageId === p).map(order => (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={order.id}
                      >
                        <TableCell component="th" scope="row">
                          <Link href={`/orders/${order.id}`}>{order.id}</Link>
                        </TableCell>
                        <TableCell>
                          <Moment format="DD/MM/YYYY">
                            {order.checkin}
                          </Moment>
                        </TableCell>
                        <TableCell>{order.product.name}</TableCell>
                        <TableCell>
                          <Currency value={order.total} />
                        </TableCell>
                        <TableCell align="right">
                          {order.status === 'started' && <Chip label="Iniciado" color="default" size="small" variant="outlined" />}
                          {order.status === 'approved' && <Chip label="Incompleto" color="primary" size="small" variant="outlined" />}
                          {order.status === 'complete' && <Chip label="A confirmar" color="secondary" size="small" variant="outlined" />}
                          {order.status === 'confirmed' && <Chip label="Confirmado" color="primary" size="small" />}
                          {order.status === 'accomplished' && <Chip label="Realizado" color="default" size="small" />}
                          {order.status === 'cancelled' && <Chip label="Cancelado" color="secondary" size="small" />}
                        </TableCell>
                      </TableRow>
                    ))}

                    <TableRow
                      hover
                      tabIndex={-1}
                      key={p}
                    >
                      <TableCell colSpan={5} align="right">
                        <Link href={`/packages/${p}`}>Ver pacote</Link>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        :
          <Empty>Selecione um usuário ou busque pelo código da reserva.</Empty>
      }
    </div>
  );
}
