import React from 'react';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Moment from 'react-moment';
import { useParams } from "react-router-dom";
import { showLead } from '../api/Leads';

export default function LeadPage() {
  let { leadId } = useParams();
  const [lead, setLead] = React.useState();

  const loadLead = () => {
    const { fetcher } = showLead(leadId);

    fetcher.then(result => {
      setLead(result.lead);
    }).catch(err => {
      console.log(err);
    });
  };

  React.useEffect(() => {
    loadLead();
  }, []);

  if (!lead) return <CircularProgress />;

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow hover>
              <TableCell><b>Nome</b>: {lead.name}</TableCell>
            </TableRow>

            <TableRow hover>
              <TableCell>
                <b>Telefone</b>:
                {' '}
                <Link href={`https://wa.me/55${lead.phone}`} target="_blank">
                  {lead.phone}
                </Link>
              </TableCell>
            </TableRow>

            {
              lead.email
              &&
              <TableRow hover>
                <TableCell><b>Email</b>: {lead.email}</TableCell>
              </TableRow>
            }

            {
              lead.destination
              &&
              <TableRow hover>
                <TableCell><b>Destino</b>: {lead.destination.name}</TableCell>
              </TableRow>
            }

            <TableRow hover>
              <TableCell>
                <b>Período</b>:
                {' '}
                {
                  lead.checkinMonth
                  ?
                  <Moment format="MM/YYYY">
                    {`${lead.checkinMonth}-01`}
                  </Moment>
                  :
                  <>
                    <Moment format="DD/MM/YYYY">
                      {lead.checkin}
                    </Moment>
                    {
                      lead.checkout
                      &&
                      <>
                        {' - '}
                        <Moment format="DD/MM/YYYY">
                          {lead.checkout}
                        </Moment>
                      </>
                    }
                  </>
                }
              </TableCell>
            </TableRow>

            {
              lead.paxRange
              &&
              <TableRow hover>
                <TableCell><b>Pax</b>: {lead.paxRange}</TableCell>
              </TableRow>
            }

            {
              lead.profile
              &&
              <TableRow hover>
                <TableCell><b>Perfil</b>: {lead.profile}</TableCell>
              </TableRow>
            }

            {
              lead.interests.length > 0
              &&
              <TableRow hover>
                <TableCell><b>Interesses</b>: {lead.interests.join(', ')}</TableCell>
              </TableRow>
            }

            {
              lead.comments
              &&
              <TableRow hover>
                <TableCell><b>Comentários</b>: {lead.comments}</TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
